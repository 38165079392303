
import { defineComponent, onMounted, reactive, ref } from "vue";
import { isPlatform } from "@ionic/vue";
import Header from "@/components/Header.vue";
import { CalendarNative } from "@/utils/CalendarNative";

export default defineComponent({
  name: "ScheduleSetting",
  components: {
    Header,
  },
  setup() {
    const isSyncNative = ref(false);
    const bindChangeSyncNative = (flag: any) => {
      isSyncNative.value = flag;
      localStorage.setItem("isSyncNative", flag);
      if (!flag) {
        bindChangeAccount("");
      }
    };

    const isPlat = ref(false);
    const emailAccounts = reactive({ data: new Array<any>() });
    const calendarNative = new CalendarNative();

    const loadIosCalendar = () => {
      emailAccounts.data = [];
      calendarNative.findAllCalendarNative().then(
        (r: any) => {
          if (r && r.length > 0) {
            r.forEach((ele: any) => {
              const ios = {
                id: ele.id,
                name: ele.name,
                account: "",
                type: ele.type,
              };
              emailAccounts.data.unshift(ios);
            });
          }
        },
        (e: any) => {}
      );
    };

    const loadAndroidCalendar = () => {
      emailAccounts.data = [];
      calendarNative.findAllCalendarNative().then(
        (r: any) => {
          if (r && r.length > 0) {
            r.forEach((ele: any) => {
              const android = {
                id: ele.id,
                name: ele.name,
                account: ele.displayname,
              };
              emailAccounts.data.push(android);
            });
          }
        },
        (e: any) => {}
      );
    };

    let syncNativeAccount = ref(localStorage.getItem("syncNativeAccount"));
    const bindChangeAccount = (val: any) => {
      localStorage.setItem("syncNativeAccount", val);
      syncNativeAccount.value = val;
    };

    onMounted(() => {
      const flag = localStorage.getItem("isSyncNative");
      console.log(flag);
      
      if (flag != undefined && flag != null) {
        isSyncNative.value = flag == "true";
      }
      console.log("isSyncNative.value"+isSyncNative.value);
      if (isPlatform("ios")) {
        isPlat.value = true;
        loadIosCalendar();
      } else {
        isPlat.value = false;
        loadAndroidCalendar();
      }
    });
    return {
      isSyncNative,
      bindChangeSyncNative,
      isPlat,
      emailAccounts,
      bindChangeAccount,
      syncNativeAccount,
    };
  },
});
